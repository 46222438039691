import ApiRequest from "../util/ApiRequest";
import apiConfig from "../util/apiConfig";

export default class Detection {
  getAll = async (page, limit, enrollmentId = null, startDate = null, endDate = null, caseId = null, userId = null, keyword = null, id = null) => {
    const filter = {}

    if(page) filter.page = page
    if(limit) filter.limit = limit
    if(enrollmentId) filter.enrollment_id = enrollmentId
    if(startDate) filter.start_date = startDate
    if(endDate) filter.end_date = endDate
    if(caseId) filter.case_id = caseId
    if(userId) filter.user_id = userId
    if(keyword) filter.keyword = keyword
    if(id) filter.id = id

    return await ApiRequest.set(`${apiConfig.komune_url}/detection?${new URLSearchParams(filter)}`, "GET",);
  }

  getTopTarget = async () => {
    return await ApiRequest.set(`${apiConfig.komune_url}/detection/top-target`, "GET",);
  }

  getDetectionDistribution = async () => {
    return await ApiRequest.set(`${apiConfig.komune_url}/detection/distribution`, "GET",);
  }

  getAssociates = async (id) => {
    return await ApiRequest.set(`${apiConfig.komune_url}/detection/associate?id=${id}`, "GET",);
  }

  getById = async (id) => {
    return await ApiRequest.set(`${apiConfig.komune_url}/detection/${id}`, "GET",);
  }
}
