import ApiRequest from "../util/ApiRequest";
import apiConfig from "../util/apiConfig";

export default class CustomLogo {
    getAll = async () => {
        return await ApiRequest.set(`${apiConfig.komune_url}/v1/custom-logo`, "GET",);
    }

    insert = async (body) => {
        return await ApiRequest.set(`${apiConfig.komune_url}/v1/custom-logo`, "POST", body);
    }

    update = async (body) => {
        return await ApiRequest.set(`${apiConfig.komune_url}/v1/custom-logo`, "PUT", body);
    }
}
