import React, {Component, Suspense, lazy, useEffect} from 'react';
import {Switch, Route, Redirect, HashRouter, useHistory} from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import EmotionAnalysisReport from "./emotion-analysis-report/EmotionAnalysisReport";

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Account = lazy(() => import('./account/Account'));
const AccountCreate = lazy(() => import('./account/AccountCreate'));
const AccountUpdate = lazy(() => import('./account/AccountUpdate'));
const Detection = lazy(() => import('./detection/Detection'));
const DetectionDetail = lazy(() => import('./detection-detail/DetectionDetail'));
const DetectionDetailPlate = lazy(() => import('./detection-detail-plate/DetectionDetailPlate'));
const FaceEnrollment = lazy(() => import('./face-enrollment/FaceEnrollment'));
const FaceEnrollmentCreate = lazy(() => import('./face-enrollment/FaceEnrollmentCreate'));
const FaceEnrollmentUpdate = lazy(() => import('./face-enrollment/FaceEnrollmentUpdate'));
const VehicleEnrollment = lazy(() => import('./vehicle-enrollment/VehicleEnrollment'));
const VehicleEnrollmentCreate = lazy(() => import('./vehicle-enrollment/VehicleEnrollmentCreate'));
const VehicleEnrollmentUpdate = lazy(() => import('./vehicle-enrollment/VehicleEnrollmentUpdate'));
const Case = lazy(() => import('./case/Case'));
const CaseCreate = lazy(() => import('./case/CaseCreate'));
const CaseUpdate = lazy(() => import('./case/CaseUpdate'));
const CustomLogo = lazy(() => import('./custom-logo/CustomLogo'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));

function AppRoutes() {
    return (
        <HashRouter>
            <Suspense fallback={<Spinner/>}>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard}/>
                    <Route exact path="/account" component={Account}/>
                    <Route exact path="/account/create" component={AccountCreate}/>
                    <Route exact path="/account/:id/update" component={AccountUpdate}/>
                    <Route exact path="/detection/:case_id" component={Detection}/>
                    <Route exact path="/detection-detail/:id" component={DetectionDetail}/>
                    <Route exact path="/detection-detail/:plate/plate" component={DetectionDetailPlate}/>
                    <Route exact path="/face-enrollment" component={FaceEnrollment}/>
                    <Route exact path="/face-enrollment/create" component={FaceEnrollmentCreate}/>
                    <Route exact path="/face-enrollment/:nik/update" component={FaceEnrollmentUpdate}/>
                    <Route exact path="/vehicle-enrollment" component={VehicleEnrollment}/>
                    <Route exact path="/vehicle-enrollment/create" component={VehicleEnrollmentCreate}/>
                    <Route exact path="/vehicle-enrollment/:id/update" component={VehicleEnrollmentUpdate}/>
                    <Route exact path="/case" component={Case}/>
                    <Route exact path="/case/create" component={CaseCreate}/>
                    <Route exact path="/case/:id/update" component={CaseUpdate}/>
                    <Route exact path="/custom-logo" component={CustomLogo}/>
                    <Route exact path="/emotion-analysis/report" component={EmotionAnalysisReport}/>

                    <Route path="/basic-ui/buttons" component={Buttons}/>
                    <Route path="/basic-ui/dropdowns" component={Dropdowns}/>
                    <Route path="/basic-ui/typography" component={Typography}/>

                    <Route path="/form-Elements/basic-elements" component={BasicElements}/>

                    <Route path="/tables/basic-table" component={BasicTable}/>

                    <Route path="/icons/mdi" component={Mdi}/>

                    <Route path="/charts/chart-js" component={ChartJs}/>


                    <Route path="/login" component={Login}/>
                    <Route path="/user-pages/register-1" component={Register1}/>

                    <Route path="/error-pages/error-404" component={Error404}/>
                    <Route path="/error-pages/error-500" component={Error500}/>


                    <Redirect to="/dashboard"/>
                </Switch>
            </Suspense>
        </HashRouter>
    );
}

export default AppRoutes;
