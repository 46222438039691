import React, {useEffect, useRef, useState} from 'react';
import {PolarArea, Line} from "react-chartjs-2";
import {
    Chart,
    LinearScale,
    RadialLinearScale,
    ArcElement,
    CategoryScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip
} from "chart.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetectionModel from "../../models/Detection";
import moment from "moment";
import CustomLogo from "../../models/CustomLogo";

Chart.register(LinearScale, RadialLinearScale, ArcElement, CategoryScale, PointElement, LineElement, Legend, Tooltip)

export default function EmotionAnalysisReport(props) {
    const customLogoModel = new CustomLogo()
    const detectionModel = new DetectionModel();
    let predictedRef = useRef(null)
    const params = new URLSearchParams(props.location.search);
    const mobile = params.get('mobile')
    const download = params.get('download')
    const detection_id = params.get('detection_id')
    const [demography, setDemography] = useState({})
    const [customLogo, setCustomLogo] = useState({});

    let dataTemp = params.get('data')

    if(dataTemp) {
        dataTemp = JSON.parse(dataTemp);
        dataTemp.top_10 = Object.fromEntries(
            Object.entries(dataTemp.top_10).sort( (a,b) => b[1] -a[1] )
        )
    }

    const [data, setData] = useState(dataTemp ? dataTemp : {})



    useEffect(() => {
        const getDetectionDetail = async () => {
            try {
                const response = await detectionModel.getAll(null, null, null, null, null, null, null, null, detection_id)

                let dataTemp = JSON.parse(response.data[0].emotion_analysis);
                dataTemp.top_10 = Object.fromEntries(
                    Object.entries(dataTemp.top_10).sort((a, b) => b[1] - a[1])
                )

                setDemography({
                    name: response.data[0].full_name,
                    nik: response.data[0].nik,
                    job: response.data[0].name,
                    created_at: response.data[0].created_at
                })
                setData(dataTemp)
            } catch (e) {
                console.log(e)
            }
        }

        if(detection_id) getDetectionDetail()

        const getCustomLogo = async () => {
            try {
                const data = await customLogoModel.getAll();

                setCustomLogo(data[0])
            } catch (e) {
                console.log(e)
            }
        }

        getCustomLogo()
    }, [])

    const generatePDF = async () => {
        const canvas = await html2canvas(predictedRef.current, {
            scale: 1.1,
            dpi: 320,
        });

        let imgWidth = 250;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const image = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF('p', 'mm', [250, 800]);
        await pdf.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight);
        await pdf.save(`Emotion Analysis (${demography.name} - ${moment(demography.created_at).unix()}).pdf`);

    }

    const optionsPolar = {
        responsive: true,
        scales: {
            y: {
                min: 0,
                max: 100,
            },
            r: {
                pointLabels: {
                    display: true,
                    centerPointLabels: true,
                    font: {
                        size: 18
                    }
                },
                min: 0,
                max: 100,
            }
        },
        plugins: {
            legend: {
                position: 'top',
            }
        }

    }

    const optionsEmotion = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,

        scales: {
            y: {
                min: 0,
                max: 100,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
        }
    }
    const optionsEngangment = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,

        scales: {
            y: {
                min: 0,
                max: 100,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },

        }
    }

    return (
        <div style={{backgroundColor: 'white'}}>
            {download &&
                <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 30}}>
                    <button onClick={generatePDF} className="btn btn-primary mr-4">Download PDF</button>
                </div>
            }


            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: mobile ? 0 : 50}}
                 ref={predictedRef}>
                <div style={{width: mobile ? '100%' : '80%'}}>
                    <div style={{width: '100%', marginBottom: 30}}>
                        {customLogo.image && <div style={{display: 'flex', justifyContent: 'center'}}><img src={`data:image/png;base64,${customLogo.image}`} alt="logo"
                                                       style={{height: customLogo.height, objectFit: 'contain', marginBottom: 30}}/></div>
                        }

                        {demography.name &&
                            <div style={{border: '1px solid black', padding: 30, marginBottom: 30, borderRadius: 5}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <h4 style={{color: '#333', marginBottom: 10, flex: 1}}>{demography.name}</h4>
                                    <div style={{
                                        color: '#333',
                                        marginBottom: 10
                                    }}>{moment(demography.created_at).format('ddd, DD MMM YYYY - HH:mm')}</div>
                                </div>

                                <div style={{color: '#333', marginBottom: 10}}>NIK : {demography.nik || '-'}</div>
                                <div style={{color: '#333'}}>Job : {demography.job || '-'}</div>
                            </div>
                        }


                        <h2 style={{color: '#333', textAlign: 'center', marginBottom: 10}}>Quadrant Polar Area</h2>

                        {data.quadrant &&
                            <PolarArea type='polarArea'
                                       options={optionsPolar} data={{
                                labels: ['High Control', 'Conductive', 'Low Control', 'Obstructive'],
                                datasets: [
                                    {
                                        label: '# of Votes',
                                        data: [data.quadrant.highControl, data.quadrant.conductive, data.quadrant.lowControl, data.quadrant.obstructive],
                                        backgroundColor: [
                                            'rgba(75, 192, 192, 0.5)',
                                            'rgba(54, 162, 235, 0.5)',
                                            'rgba(255, 99, 132, 0.5)',
                                            'rgba(255, 206, 86, 0.5)',
                                        ],
                                        borderWidth: 1,
                                    },
                                ],
                            }}/>
                        }
                    </div>

                    <div style={{width: '100%', marginBottom: 30}}>
                        <h2 style={{color: '#333', textAlign: 'center', marginBottom: 10}}>Emotions Over Time</h2>
                        {data.emotions_over_time &&
                            <Line options={optionsEmotion} data={{
                                labels: data.emotions_over_time[Object.keys(data.emotions_over_time)[0]].map((data, idx) => idx + 1),
                                datasets: [
                                    {
                                        label: 'Angry',
                                        data: data.emotions_over_time["Angry"] ? data.emotions_over_time["Angry"].map(data => data) : [],
                                        borderColor: 'rgb(168, 54, 50)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Disgust',
                                        data: data.emotions_over_time["Disgust"] ? data.emotions_over_time["Disgust"].map(data => data) : [],
                                        borderColor: 'rgb(76, 168, 50)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Fear',
                                        data: data.emotions_over_time["Fear"] ? data.emotions_over_time["Fear"].map(data => data) : [],
                                        borderColor: 'rgb(147, 41, 166)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Happy',
                                        data: data.emotions_over_time["Happy"] ? data.emotions_over_time["Happy"].map(data => data) : [],
                                        borderColor: 'rgb(189, 191, 31)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Sad',
                                        data: data.emotions_over_time["Sad"] ? data.emotions_over_time["Sad"].map(data => data) : [],
                                        borderColor: 'rgb(40, 143, 191)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Surprise',
                                        data: data.emotions_over_time["Surprise"] ? data.emotions_over_time["Surprise"].map(data => data) : [],
                                        borderColor: 'rgb(224, 132, 160)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Neutral',
                                        data: data.emotions_over_time["Neutral"] ? data.emotions_over_time["Neutral"].map(data => data) : [],
                                        borderColor: 'rgb(81, 87, 89)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                ],
                            }}/>
                        }
                    </div>

                    <div style={{width: '100%', marginBottom: 30}}>
                        <h2 style={{color: '#333', textAlign: 'center', marginBottom: 10}}>Engagement Over Time</h2>

                        {data.engagement_over_time &&
                            <Line options={optionsEngangment} data={{
                                labels: data.engagement_over_time[Object.keys(data.engagement_over_time)[0]].map((data, idx) => idx + 1),
                                datasets: [
                                    {
                                        label: 'Attention',
                                        data: data.engagement_over_time["Attention"] ? data.engagement_over_time["Attention"].map(data => data) : [],
                                        borderColor: 'rgb(48, 136, 156)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Valence',
                                        data: data.engagement_over_time["Valence"] ? data.engagement_over_time["Valence"].map(data => data) : [],
                                        borderColor: 'rgb(156, 111, 48)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },
                                    {
                                        label: 'Positivity',
                                        data: data.engagement_over_time["Positivity"] ? data.engagement_over_time["Positivity"].map(data => data) : [],
                                        borderColor: 'rgb(76, 168, 50)',
                                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        spanGaps: false,
                                    },

                                ],
                            }}/>
                        }
                    </div>

                    <div style={{width: '100%', marginBottom: 30}}>
                        <h2 style={{color: '#333', textAlign: 'center', marginBottom: 10}}>Top-10 circumplex model of
                            affect</h2>

                        {
                            data.top_10 && Object.keys(data.top_10).map(key => {
                                return (
                                    <>
                                        <div className="row-info">
                                            <div className="label-info">
                                                {key}
                                            </div>
                                            <div className="detail-info">
                                                {data.top_10[key]} %
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
