import ApiRequest from "../util/ApiRequest";
import apiConfig from "../util/apiConfig";

export default class Case {
  insert = async (body) => {
    return await ApiRequest.set(`${apiConfig.komune_url}/cases`, "POST", body);
  }

  getAll = async () => {
    return await ApiRequest.set(`${apiConfig.komune_url}/cases`, "GET",);
  }

  update = async (id, body) => {
    return await ApiRequest.set(`${apiConfig.komune_url}/cases/${id}`, "PUT", body);
  }

  delete = async (id) => {
    return await ApiRequest.set(`${apiConfig.komune_url}/cases/${id}`, "DELETE");
  }
}
