import React, {Component, useEffect, useState} from 'react';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {Collapse, Dropdown} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import Case from "../../models/Case";
import CustomLogo from "../../models/CustomLogo";

function Sidebar(props) {
    const caseModel = new Case()
    const customLogoModel = new CustomLogo()
    const history = useHistory()
    const [isReportExpanded, setReportExpanded] = useState(props.location.hash.startsWith('#/detection/'))
    const [cases, setCases] = useState([]);
    const [customLogo, setCustomLogo] = useState({});

    useEffect(() => {
        const getCases = async () => {
            try {
                const data = await caseModel.getAll();

                setCases(data)
            } catch (e) {
                console.log(e)
            }
        }

        const getCustomLogo = async () => {
            try {
                const data = await customLogoModel.getAll();

                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }

                link.href = `data:image/png;base64,${data[0].image}`;

                setCustomLogo(data[0])
            } catch (e) {
                console.log(e)
            }
        }

        getCases()
        getCustomLogo()
    }, [])

    const isPathActive = (path) => {
        if(!props.location.hash.includes('emotion-analysis/report')) {
            if(!sessionStorage.getItem('token')) {
                window.location.href = '/#/login';
            }
        }
        console.log('check 1', props.location.hash)
        console.log('check 2', `#${path}`)
        return props.location.hash === `#${path}`;
    }

    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{minHeight: '100vh'}}>
            <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top"
                 style={{height: customLogo.height + 20}}>
                <a className="sidebar-brand brand-logo" href="#/custom-logo" style={{display: 'flex', alignItems: 'center', paddingLeft: 0}}>
                    {customLogo.image && <img src={`data:image/png;base64,${customLogo.image}`} alt="logo"
                         style={{height: customLogo.height, objectFit: 'contain'}}/>
                    }
                </a>
                {/*<a className="sidebar-brand brand-logo-mini" href="index.html"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></a>*/}
            </div>
            <ul className="nav" style={{paddingTop: 135}}>
                <li className={isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                    <Link className="nav-link" to="/dashboard" onClick={(e) => {
                        e.preventDefault()
                        history.push('/#/dashboard')
                        window.location.reload();
                    }}>
                        <span className="menu-icon"><i className="mdi mdi-home"></i></span>
                        <span className="menu-title"><Trans>Dashboard</Trans></span>
                    </Link>
                </li>
                <li className={isPathActive('/account') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                    <Link className="nav-link" to="/#/account" onClick={(e) => {
                        e.preventDefault()
                        history.push('/#/account')
                        window.location.reload();
                    }}>
                        <span className="menu-icon"><i className="mdi mdi-account-multiple"></i></span>
                        <span className="menu-title"><Trans>Account</Trans></span>
                    </Link>
                </li>
                <li className={isPathActive('/face-enrollment') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                    <Link className="nav-link" to="/#/face-enrollment" onClick={(e) => {
                        e.preventDefault()
                        history.push('/#/face-enrollment')
                        window.location.reload();
                    }}>
                        <span className="menu-icon"><i className="mdi mdi-face"></i></span>
                        <span className="menu-title"><Trans>Database Wajah</Trans></span>
                    </Link>
                </li>

                <li className={isPathActive('/vehicle-enrollment') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                    <Link className="nav-link" to="/vehicle-enrollment" onClick={(e) => {
                        e.preventDefault()
                        history.push('/#/vehicle-enrollment')
                        window.location.reload();
                    }}>
                        <span className="menu-icon"><i className="mdi mdi-car"></i></span>
                        <span className="menu-title"><Trans>Database Kendaraan</Trans></span>
                    </Link>
                </li>
                <li className={isPathActive('/case') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                    <Link className="nav-link" to="/case" onClick={(e) => {
                        e.preventDefault()
                        history.push('/#/case')
                        window.location.reload();
                    }}>
                        <span className="menu-icon"><i className="mdi mdi-file-document"></i></span>
                        <span className="menu-title"><Trans>Kategori Kasus</Trans></span>
                    </Link>
                </li>
                {/*<li className={isPathActive('/detection') ? 'nav-item menu-items active' : 'nav-item menu-items'}>*/}
                {/*    <Link className="nav-link" to="/detection" onClick={(e) => {*/}
                {/*        e.preventDefault()*/}
                {/*        history.push('/#/detection')*/}
                {/*        window.location.reload();*/}
                {/*    }}>*/}
                {/*        <span className="menu-icon"><i className="mdi mdi-comment-alert"></i></span>*/}
                {/*        <span className="menu-title"><Trans>Laporan</Trans></span>*/}
                {/*    </Link>*/}
                {/*</li>*/}

                <li className={ props.location.hash.startsWith('#/detection/') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
                    <div className={ isReportExpanded ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => setReportExpanded(!isReportExpanded) } data-toggle="collapse">
                        <span className="menu-icon"><i className="mdi mdi-comment-alert"></i></span>
                        <span className="menu-title"><Trans>Laporan</Trans></span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={ isReportExpanded }>
                        <div>
                            <ul className="nav flex-column sub-menu">
                                {
                                    cases.map(aCase => {
                                        return (
                                            <li className="nav-item"> <Link className={ isPathActive(`/detection/${aCase.id}`) ? 'nav-link active' : 'nav-link' }
                                                                            onClick={(e) => {
                                                                                e.preventDefault()
                                                                                history.push(`/#/detection/${aCase.id}`)
                                                                                window.location.reload();
                                                                            }}
                                                                            to={`/detection/${aCase.id}`}><Trans>{aCase.name}</Trans></Link></li>
                                        )
                                    })
                                }

                                {/*<li className="nav-item"> <Link className={ isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>*/}
                                {/*<li className="nav-item"> <Link className={ isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography"><Trans>Typography</Trans></Link></li>*/}
                            </ul>
                        </div>
                    </Collapse>
                </li>
            </ul>
        </nav>
    );
}

export default withRouter(Sidebar);
