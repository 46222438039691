import apiConfig from "./apiConfig";
import {useHistory} from "react-router-dom";
// import GlobalData from "./GlobalData";

export default class ApiRequest {

  static set = async (endpoint, method, body, headers) => {
    console.log(window.location)
    // const history = useHistory();

    // let token = GlobalData.token ? GlobalData.token : Cookies.get("token") ? Cookies.get("token") : null

    console.log('HEADERS: ', headers);
    console.log('API ACCESS: ' + endpoint.includes('http') ? endpoint : apiConfig.base_url + endpoint);
    console.log(window.location.origin.slice(0, window.location.origin.length - 5))

    let response = await fetch(endpoint.includes('http') ? endpoint : apiConfig.base_url + endpoint, {
      method: method,
      headers: headers ? headers :
        {
          'Content-Type': 'application/json',
          Authorization : sessionStorage.getItem("token") ? `bearer ${sessionStorage.getItem("token")}` : null,
          Accept: 'application/json',
        },
      body: JSON.stringify(body)
    });

    if (response.ok) {
      return response.json()
    } else {
      let error = await response.json()

      console.log(error)

      throw error
    }
  }

  static setMultipart = async (endpoint, method, body) => {
    let response = await fetch(endpoint.includes('http') ? endpoint : apiConfig.base_url + endpoint, {
      method: method,
      headers:
          {
            Authorization : localStorage.getItem("token") ? `bearer ${localStorage.getItem("token")}` : null,
          },
      body: body
    });

    if (response.ok) {
      return response.json()
    } else {

      let error = await response.json()

      console.log(error)

      throw error

    }
  }
}
