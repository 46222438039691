module.exports={
  // base_url:`http://10.7.2.46:4004`, //INTERNAL NF
  // nf_base_url: `http://10.7.2.46`, //INTERNAL NF
  // base_url_without_port:`http://localhost:` //INTERNAL NF
  // base_url:`http://192.168.100.101:4004`, //KOMUNE DEV
  // nf_base_url: `http://192.168.100.101`, //KOMUNE DEV
  // base_url_without_port:`http://192.168.100.101:` //KOMUNE DEV
  base_url:`http://10.161.4.30:4004`, //BC
  nf_base_url: `http://10.161.4.30:8001`, //BC
  base_url_without_port:`${window.location.origin.slice(0, window.location.origin.length - 4)}`, //BC,
  komune_url: `https://kpu-api.komunestudio.com`
};
